module.exports = [{
      plugin: require('/tmp/68b68626/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/68b68626/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/68b68626/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"showCaptions":true,"backgroundColor":"transparent","quality":75,"loading":"lazy"},
    },{
      plugin: require('/tmp/68b68626/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
